<template>
  <el-dialog append-to-body
      title="完善档案信息"
      :visible.sync="visible"
      width="500px"
      @close="handleInvoiceUpdateCancel"
  >
    <div class="invoice-update">
      <el-form ref="invoiceUpdate" :model="invoiceUpdate" :rules="rules" label-position="top">
        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <el-form-item prop="accountStatus" label="记账状态">
              <el-select v-model="invoiceUpdate.accountStatus" placeholder="请选择记账状态">
                <el-option v-for="item in accountStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="记账所属期" prop="periodNo" placeholder="请输入记账所属期">
              <el-date-picker
                  v-model="invoiceUpdate.periodNo"
                  type="date"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerDisabled"
                  placeholder="请选择时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <el-form-item label="凭证编号" prop="voucherNo">
              <el-input v-model="invoiceUpdate.voucherNo" placeholder="请输入凭证编号" maxlength="8"/>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="报账单号" prop="reimburseOrderNo">
              <el-input v-model="invoiceUpdate.reimburseOrderNo" placeholder="请输入报账单号" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row type="flex" justify="space-between">
          <el-col :span="11">
            <el-form-item label="报账事项" prop="reimburseMatters">
              <el-input v-model="invoiceUpdate.reimburseMatters" placeholder="请输入报账事项" />
            </el-form-item>
          </el-col>
          <el-col :span="11">
          </el-col>
        </el-row>
  
      </el-form>
    </div>

    <div slot="footer" v-if="ifShow" class="dialog-footer">
      <el-button type="primary" @click="handleInvoiceUpdateCancel">确 认</el-button>
    </div>
    <div slot="footer" v-else class="dialog-footer">
      <el-button @click="handleInvoiceUpdateCancel">取 消</el-button>
      <el-button type="primary" @click="handleInvoiceUpdateConfirm('invoiceUpdate')">保 存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { tableStyle, changeMethods } from '@/util/mixins'
import { invoiceExplainUpdate } from '@/service/invoice-center'
import RegexLib from '@/assets/js/regex-lib'

export default {
  name: 'InvoiceFilingDetail',
  mixins: [tableStyle, changeMethods],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    invoiceUpdate: {
      type: Object
    }
  },
  data () {
    return {
      rules: {
        // invoiceCode: [
        //   { required: true, message: '请输入发票代码', trigger: 'blur' }
        // ], 
      },
      visible: false,
      updateVisible: false,
      invoiceTypeSet: [],
      accountStatusList: [
        { value: '', label: '全部' },
        { value: 0, label: '未记账' },
        { value: 1, label: '已记账' },
      ],
      pickerDisabled: {
        disabledDate (date) {
          return date.getTime() > Date.now()
        }
      },
    }
  },
  watch: {
    show () {
      this.visible = this.show
    }
  },
  created () {
    this.invoiceTypeSet = this.$store.state.enums.InvoiceType
  },
  computed: {
    ifShow () {
      return this.invoiceUpdate.action == 'details'
    },
  },
  methods: {
    /* 发票修改确认 */
    handleInvoiceUpdateConfirm (invoiceUpdate) {
      this.$refs[invoiceUpdate].validate((valid) => {
        if (valid) {
          const param = {
            ids: [this.invoiceUpdate.id],
            data: {
              id: this.invoiceUpdate.id,
              accountStatus: this.invoiceUpdate.accountStatus,
              periodNo: this.invoiceUpdate.periodNo,
              voucherNo: this.invoiceUpdate.voucherNo,
              reimburseOrderNo: this.invoiceUpdate.reimburseOrderNo,
              reimburseMatters: this.invoiceUpdate.reimburseMatters
            },
          };
          invoiceExplainUpdate(param).then((res) => {
            if (res.success) {
              this.visible = false;
              this.$emit('save');
              // this.toast("档案信息保存成功！", "success");
            }
          });
        } else {
          return false
        }
      })
    },
    /* 发票修改取消 */
    handleInvoiceUpdateCancel () {
      this.$refs.invoiceUpdate.resetFields()
      this.visible = false
      this.$emit('cancel')
    },
  }
}
</script>

<style scoped lang="scss">
.invoice-update {
  .el-row {
    margin-left: 20px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .el-col {
    text-align: left;
    border-radius: 4px;
  }
}
</style>
