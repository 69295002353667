<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form class="select-form" ref="queryForm" :model="queryForm" label-width="100px" @keyup.enter.native="submitForm">
          <div>
            <el-form-item prop="classification" label="分类">
              <el-select v-model="queryForm.classification" placeholder="请选择分类">
                <el-option v-for="item in classificationList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="invoiceType" label="发票类型">
              <el-select v-model="queryForm.invoiceType" placeholder="请选择发票类型">
                <el-option v-for="item in invoiceTypes" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="invoiceStatus" label="发票状态">
              <el-select v-model="queryForm.invoiceStatus" placeholder="请选择发票状态">
                <el-option v-for="item in invoiceStatusList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item prop="invoiceTime" label="开票日期">
              <el-date-picker size="mini" v-model="invoiceTimeRange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM" range-separator="~" type="months" :picker-options="gxrqDateRangeOptions"> </el-date-picker>
            </el-form-item>
            <el-form-item prop="buyerName" label="购方名称">
              <el-input v-model.trim="queryForm.buyerName" placeholder="请输入购方名称" @keyup.enter.native="submitForm()" />
            </el-form-item>
            <el-form-item prop="reimburseOrderNo" label="报账单号">
              <el-input v-model.trim="queryForm.reimburseOrderNo" placeholder="请输入报账单号" maxlength="12" @keyup.enter.native="submitForm()" />
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item prop="invoiceCode" label="发票代码">
              <el-input v-model.trim="queryForm.invoiceCode" placeholder="请输入发票代码" maxlength="12" @keyup.enter.native="submitForm()" />
            </el-form-item>
            <el-form-item prop="invoiceNo" label="发票号码">
              <el-input v-model.trim="queryForm.invoiceNo" placeholder="请输入发票号码" maxlength="8" @keyup.enter.native="submitForm()" />
            </el-form-item>
            <el-form-item prop="eInvoiceNo" label="数电票号">
              <el-input maxlength="20" v-model.trim="queryForm.eInvoiceNo" placeholder="请输入数电票号" @keyup.enter.native="submitForm()" />
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item prop="train21No" label="电子客票号码">
              <el-input v-model.trim="queryForm.train21No" placeholder="请输入电子客票号码" maxlength="21" @keyup.enter.native="submitForm()" />
            </el-form-item>
            <el-form-item prop="isOriginal" label="是否有原件">
              <el-select v-model="queryForm.isOriginal" placeholder="请选择是否有原件">
                <el-option v-for="item in isOriginalList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="voucherNo" label="记账凭证号">
              <el-input v-model.trim="queryForm.voucherNo" placeholder="请输入记账凭证号" @keyup.enter.native="submitForm()" />
            </el-form-item>
          </div>
          <div v-show="selectExpended">
            <el-form-item prop="sellerName" label="销方名称">
              <el-input v-model.trim="queryForm.sellerName" placeholder="请输入销方名称" @keyup.enter.native="submitForm()" />
            </el-form-item>
            <el-form-item prop="source" label="发票来源">
              <el-select v-model="queryForm.source" placeholder="请选择发票来源">
                <el-option v-for="item in sourceList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item >
            </el-form-item>
          </div>
          <div v-show="selectExpended" style="margin-bottom: 40px">
            <el-form-item prop="voucherNo" label="最小发票金额">
              <el-input v-model.trim="queryForm.minAmount" placeholder="请输入最小发票金额" @keyup.enter.native="submitForm()" />
            </el-form-item>
            <el-form-item prop="sellerName" label="最大发票金额">
              <el-input v-model.trim="queryForm.maxAmount" placeholder="请输入最大发票金额" @keyup.enter.native="submitForm()" />
            </el-form-item>
            <el-form-item >
            </el-form-item>
          </div>
          <div>
            <el-form-item style="margin-left: 32px">
              <select-button show-status @list-close="handleListClose" @list-show="handleListShow" @select="submitForm" @reset="handleReset"></select-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div>
        <div class="ygx_total_box" v-loading="ygxLoading">
          <span
            >总发票数：<label>{{ ygxSummary.total }}</label
            >&nbsp;张</span
          >
          <span
            >总发票税额：<label
              >￥{{ formatMoney(ygxSummary.taxAmount) }}</label
            ></span
          >
          <span
            >总有效税额：<label
              >￥{{ formatMoney(ygxSummary.deductTaxAmount) }}</label
            ></span
          >
          <span
            >总发票金额：<label
              >￥{{ formatMoney(ygxSummary.amount) }}</label
            ></span
          >
          <el-button
            type="text"
            @click="handleYgxSummary"
            icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
        <!-- <p-header :title="'发票归档管理' + dateRangeFmt"> -->
            <!-- <span>
              <el-button type="primary" plain :disabled="!isReadied || isApply" :loading="buttonLoading" @click="handleRetract" style="margin-right: 10px">取消勾选 </el-button>
            </span>
            <span>
              <el-button type="primary" :disabled="!isReadied || isApply" @click="handleChecked" :loading="buttonLoading" style="margin-right: 10px">勾选保存 </el-button>
            </span> -->
          <!-- <el-button type="default" :loading="buttonLoading" @click="handleExport" style="margin-right: 10px">导出 </el-button> -->
        <!-- </p-header> -->
        <div class="content-table">
          <el-table :data="tableData" stripe border ref="selectionTable" highlight-current-row v-loading="tableLoading" @selection-change="handleSelection" :header-cell-style="handleHeaderCellStyle" style="width: 100%">
            <el-table-column type="selection" width="40" fixed="left" :selectable="handleRedInvoice" />
            <el-table-column type="index" label="序号" width="60" fixed="left"></el-table-column>
            <el-table-column prop="classification" :formatter="fmtTableColumn" label="分类" width="140"></el-table-column>
            <el-table-column prop="invoiceType" :formatter="fmtTableColumn" label="发票类型" width="140"></el-table-column>
            <el-table-column prop="invoiceStatus" :formatter="fmtTableColumn" label="发票状态"></el-table-column>
            <el-table-column prop="invoiceCode" label="发票代码" width="120"></el-table-column>
            <el-table-column prop="invoiceNo" label="发票号码" width="110"></el-table-column>
            <el-table-column prop="eInvoiceNo" label="数电票号" min-width="200" />
            <el-table-column prop="train21No" label="电子客票号/21位码" width="200" />
            <el-table-column prop="invoiceTime" :formatter="fmtTableColumn" label="开票日期" width="110"></el-table-column>
            <el-table-column prop="channel" :formatter="fmtTableColumn" label="原件格式" min-width="120" />
            <el-table-column prop="buyerName" show-overflow-tooltip label="购方名称" min-width="140"></el-table-column>
            <el-table-column prop="buyerTaxNo" show-overflow-tooltip label="购方税号" min-width="140"></el-table-column>
            <el-table-column prop="sumAmount" label="价税合计"></el-table-column>
            <el-table-column prop="amount" label="金额"></el-table-column>
            <el-table-column prop="taxAmount" label="税额"></el-table-column>
            <el-table-column prop="taxRate" label="税率" :formatter="fmtTableColumn"></el-table-column>
            <el-table-column prop="deductTaxAmount" label="有效税额" width="140" style="padding: 0 4px">
              <template slot-scope="scope">
                <el-input
                  style="width: 110px"
                  v-float="2"
                  @blur="checkDeductTaxAmount(scope.row)"
                  v-model="scope.row.deductTaxAmount"
                  :disabled="
                    !(
                      scope.row.enable &&
                      scope.row.checkStatus == 'WGX' &&
                      !seEditable
                    )
                  "
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="reimburseMatters" show-overflow-tooltip label="报账事项" min-width="140"></el-table-column>
            <el-table-column prop="reimburseOrderNo" show-overflow-tooltip label="报账单号" min-width="140"></el-table-column>
            <el-table-column prop="accountStatus" :formatter="fmtTableColumn" label="记账状态"></el-table-column>
            <el-table-column prop="periodNo" label="记账所属期" width="180"></el-table-column>
            <el-table-column prop="voucherNo" label="凭证编号" width="180"></el-table-column>
            <el-table-column
              prop="operate"
              label="操作"
              width="240"
              fixed="right"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="handleViewDetails(scope.row)"
                  >详情</el-button
                >
                <el-button type="text" @click="handleInvoiceImage(scope.row)" 
                  >影像</el-button
                > 
                <el-button type="text" @click="handleImproveArchives(scope.row)" 
                  >完善档案</el-button
                >  
                <el-button type="text" @click="handleExportOriginal(scope.row)" 
                  >交付</el-button
                >  
              </template>
            </el-table-column>

          </el-table>
          <div class="table-statistics">
            <div class="statistics-left">
              <div>
                已选中：<span style="color: red"> {{ selected.total }} </span
                >&nbsp;条
              </div>
              <div>金额：{{ "￥" + formatMoney(selected.amount) }}</div>
              <div>税额：{{ "￥" + formatMoney(selected.taxAmount) }}</div>
              <div>
                有效税额：{{ "￥" + formatMoney(selected.deductTaxAmount) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-page">
        <el-pagination
          background
          v-show="oprtMode == 'CX'"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryForm.page"
          :page-sizes="pageSizes"
          :page-size="queryForm.size"
          layout="total, sizes, prev, pager, next"
          :total="summary.total"
        >
        </el-pagination>
      </div>
    </div>
    
    <!-- 票据详情弹窗 -->
    <invoice-filing-detail
      :loading="loading"
      :show.sync="drawer"
      :invoice="invoice"
    ></invoice-filing-detail>
    <!-- 票据影像弹窗 -->
    <el-image-viewer v-if="invoiceImageViewer" :url-list="invoiceImages" :on-close="handleImageViewerClose" style="z-index: 2020" />
    <!-- 完善档案弹窗 -->
    <invoice-filing-improve :invoiceUpdate="invoiceUpdate" :show.sync="updateVisible" @cancel="handleImproveArchivesCancel" @save="handleImproveArchivesSave"></invoice-filing-improve>

    <el-dialog append-to-body title="交付" :visible.sync="exportVisible" width="500px" :show-close="false">
      <invoice-filing-export @handleCancelExportOriginal="handleCancelExportOriginal" :invoiceUpdate="invoiceUpdate" @handleCancelImportDialog="exportVisible = false"></invoice-filing-export>
    </el-dialog>

  </div>
</template>

<script>
import { commonsMethods } from '@/util/mixins';
import { fmtMoney } from "@/assets/js/format-util";
import { searchInvoiceFilingPage, summaryInvoiceFiling, searchInvoiceFilingRedList } from '@/service/invoice-filing';
import { getInvoiceImageListById, selectInvoice } from '@/service/invoice-center';
import RegexLib from "@/assets/js/regex-lib";
import SelectButton from "@/components/buttons/SelectButton";
import PHeader from "@/components/pdt/PHeader";
import InvoiceFilingDetail from '@/components/invoice/InvoiceFilingDetail';
import InvoiceFilingImprove from '@/components/invoice/InvoiceFilingImprove';
import InvoiceFilingExport from '@/components/invoice/InvoiceFilingExport';
import ElImageViewer from 'element-ui/packages/image/src/image-viewer';
import { tableStyle, pagingMethods, dateOption } from "../../util/mixins";
import {MANUAL_INVOICE_TYPES} from "@/content/invoice.js";
import {
  downloadDeductExcel
} from "@/service/pre-deduction";

export default {
  name: 'PassengerIncomeDeduction',
  components: {
    PHeader,
    SelectButton,
    InvoiceFilingDetail,
    InvoiceFilingImprove,
    InvoiceFilingExport,
    ElImageViewer,
  },
  mixins: [tableStyle, pagingMethods, dateOption, commonsMethods],
  data() {
    return {
      oprtMode: 'CX', //CX=查询模式 SM=扫码模式
      isReadied: false,
      isApply: false,
      signPeriod: '',
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() >= Date.now()
        }
      },
      queryForm: {
        orgId: '',
        taxNo: '',
        buyerName: '',
        checkStatus: 'WGX',
        source: undefined,
        classification: undefined,
        isOriginal: undefined,
        reimburseOrderNo: undefined,
        invoiceCode: undefined,
        invoiceNo: undefined,
        eInvoiceNo: undefined,
        train21No: undefined,
        invoiceStatus: undefined,
        invoiceType: undefined,
        sellerName: undefined,
        checker: undefined,
        creatorName: undefined,
        matchStatus: undefined,
        minAmount: undefined,
        maxAmount: undefined,
        page: 1,
        size: 30,
      },
      ygxLoading: false,
      ygxSummary: {
        total: 0,
        amount: 0,
        taxAmount: 0,
        deductTaxAmount: 0,
      },
      summary: {
        total: 0,
        amount: 0,
        taxAmount: 0,
        deductTaxAmount: 0
      },
      selected: {
        total: 0,
        amount: 0,
        taxAmount: 0,
        deductTaxAmount: 0
      },
      submitTimeRange: [],
      invoiceTimeRange: [],
      receiveTimeRange: [],
      checkedTimeRange: [],
      taxPeriod: '',
      taxNo: '',
      isChecked: false, // 提交操作： 勾选/撤销
      tableLoading: false,
      buttonLoading: false, // 行为按钮loading
      selectExpended: false,
      visible: false,
      exportVisible: false,
      /* 票据详情数据区 */
      drawer: false,
      loading: false,
      invoice: {},
      /* 票据影像数据区 */
      invoiceImageViewer: false,
      invoiceImages: [],
      /* 票据修改数据区 */
      updateVisible: false,
      invoiceUpdate: {
        id: '',
        accountStatus: '',
        periodNo: '',
        voucherNo: '',
        reimburseOrderNo: '',
        reimburseMatters: '',
      },
      invoiceTypes: MANUAL_INVOICE_TYPES,
      // invoiceTypes: DEDECTION_PASSENGER_INVOICE_TYPES,
      isOriginalList: [{ value: '0', label: '否' }, { value: '1', label: '是' }],
      classificationList: [{ value: '1', label: '销项' }, { value: '2', label: '进项' }],
      sourceList: [
        { value: '', label: '全部' },
        { value: '1', label: '增值税平台' },
        { value: '2', label: '电子发票平台' },
        { value: '3', label: 'OCR识别' },
      ],
      invoiceStatusList: [
        { value: '', label: '全部' },
        { value: '0', label: '蓝票' },
        { value: '1', label: '红票' },
        { value: '2', label: '全额冲红' },
        { value: '3', label: '作废' },
        { value: '4', label: '部分冲红' }
      ],
      matchingState: [
        { value: '', label: '全部' },
        { value: '0', label: '未匹配' },
        { value: '1', label: '已匹配 ' },
        { value: '2', label: '匹配失败' }
      ],
      //原件格式
      typeOfReceiptList: [
        {
          value: "PHOTOGRAPH",
          label: "拍照收票",
        },
        {
          value: "SCAN",
          label: "扫码",
        },
        {
          value: "GUN",
          label: "扫码枪",
        },
        {
          value: "SCANNER",
          label: "扫描仪",
        },

        {
          value: "PICTURE",
          label: "图片上传",
        },
        {
          value: "IMG",
          label: "图片",
        },
        {
          value: "PDF",
          label: "PDF文件",
        },
        {
          value: "OFD",
          label: "OFD文件",
        },
        {
          value: "XML",
          label: "XML文件",
        },
        {
          value: "EMAIL",
          label: "邮箱",
        },
        {
          value: "SUPPLIER",
          label: "结算收票 - 供应商",
        },
        {
          value: "RETAILER",
          label: "结算收票 - 零售商",
        },
        {
          value: "ISSUE",
          label: "系统开具",
        },
        {
          value: "INCOME",
          label: "进项推送",
        },
        {
          value: "SALES",
          label: "销项推送",
        },
        {
          value: "VERIFY",
          label: "查验推送",
        },
        {
          value: "WECHAT",
          label: "微信卡包",
        },
        {
          value: "FENG_LIAN",
          label: "峰连科技",
        },
        {
          value: "MANUNAL",
          label: "手工录入",
        },
      ],
      // 进项发票信息
      dateRange: '',
      selections: [],
      tableData: [],
      gxrqDateRangeOptions: {
        disabledDate: (time) => {
          // console.log('计算禁止日期...');
          return time.getTime() > this.getGxfwEndDate();
        }
      }
    };
  },
  computed: {
    dateRangeFmt() {
      if (this.dateRange) {
        let arr = this.dateRange.split('');
        arr.splice(17, 0, '日');
        arr.splice(15, 0, '月');
        arr.splice(13, 0, '年');
        arr.splice(8, 0, '日');
        arr.splice(6, 0, '月');
        arr.splice(4, 0, '年');
        return arr.join('');
      } else {
        return "--";
      }
    },
    seEditable() {
      switch (this.$store.state.deductAuthorize.sqpt) {
        case "RPA":
          return false;
        default:
          return true;
      }
    },
  },
  watch: {
    selections: {
      handler() {
        this.tableData.forEach((i) => (i.enable = false));
        let totalAmount = 0,
          totalTaxAmount = 0,
          totalDeductTaxAmount = 0;
        this.selections.forEach((i) => {
          i.enable = true;
          totalAmount = Number(Number(totalAmount + Number(i.amount)).toFixed(2));
          totalTaxAmount = Number(Number(totalTaxAmount + Number(i.taxAmount)).toFixed(2));
          totalDeductTaxAmount = Number(Number(totalDeductTaxAmount + Number(i.deductTaxAmount)).toFixed(2));
        });
        this.selected = {
          total: this.selections.length,
          amount: totalAmount,
          taxAmount: totalTaxAmount,
          deductTaxAmount: totalDeductTaxAmount
        };
        this.tableData.forEach((i) => {
          if (!i.enable) i.deductTaxAmount = i.taxAmount;
        });
      },
      deep: true
    }
  },
  created() {
    this.refreshTableData(this.queryForm);
  },
  methods: {
    handleYgxSummary() {
      this.doGetIncomeInvoicesYGXStatistics(this.queryForm);
    },
    /* 数据统计 */
    // async doGetIncomeInvoicesStatistics(param) {
    //   const { success, data } = await summaryInvoiceFiling(param);
    //   if (success) {
    //     this.summary = data;
    //   }
    // },
    /* 提交前检查 */
    validateInvoiceContent(invoice) {
      if (['GenerInvoice', 'SpecInvoice', 'EleInvoice', 'EleSpecInvoice', 'FullElePaperGenerInvoice', 'FullElePaperSpecInvoice', 'FullEleSpecInvoice', 'FullEleGenerInvoice'].indexOf(invoice.invoiceType) < 0) {
        return '扫描失败，不支持的发票类型！';
      }
      // 全电票没有发票代码
      if (invoice.invoiceType != 'FullEleSpecInvoice' && invoice.invoiceType != 'FullEleGenerInvoice') {
        if (!RegexLib.InvoiceCode.test(invoice.invoiceCode)) {
          return '扫描失败，税控发票代码无效！';
        }
        if (!RegexLib.InvoiceNo.test(invoice.invoiceNo)) {
          return '扫描失败，税控发票号码无效！';
        }
      }
      return '';
    },
    // 分页查询
    submitForm() {
      let arg = this.getQueryParam();
      // console.log("arg======", arg);
      this.refreshTableData(arg);
    },
    handleReset() {
      this.queryForm = {
        orgId: '',
        taxNo: '',
        checkStatus: 'WGX',
        invoiceCode: undefined,
        invoiceNo: undefined,
        invoiceType: undefined,
        sellerName: undefined,
        checker: undefined,
        creatorName: undefined,
        eInvoiceNo: undefined,
        matchStatus: undefined
      }
    },
    // 收起筛选列表
    handleListClose() {
      this.selectExpended = false;
    },
    // 展开筛选列表
    handleListShow() {
      this.selectExpended = true;
    },
    //处理红票
    handleRedInvoice(row) {
      if (row.amount < 0) {
        return false;
      }
      return true;
    }, 
    // 计算日期范围
    getQueryParam() {
      let queryParam;
      queryParam = this.addDateRange(
        this.queryForm,
        this.submitTimeRange,
        "SubmitTime"
      );
      queryParam = this.addDateRange(
        queryParam,
        this.invoiceTimeRange,
        "InvoiceTime"
      );
      queryParam = this.addDateRange(
        queryParam,
        this.receiveTimeRange,
        "ReceiveTime"
      );
      queryParam = this.addDateRange(
        queryParam,
        this.checkedTimeRange,
        "CheckedTime"
      );
      // console.log("get======", queryParam);
      return queryParam;
    },
    // 重置表单
    resetForm() {
      this.$refs.queryForm.resetFields();

      this.submitTimeRange = [];
      this.invoiceTimeRange = [];
      this.receiveTimeRange = [];
      this.checkedTimeRange = [];
      let orgId = this.queryForm.orgId;
      let taxNo = this.queryForm.taxNo;
      this.queryForm = {
        orgId: orgId,
        taxNo: taxNo,
        checkStatus: 'WGX',
        invoiceCode: undefined,
        invoiceNo: undefined,
        invoiceType: undefined,
        sellerName: undefined,
        checker: undefined,
        creatorName: undefined,
        eInvoiceNo: undefined,
        matchStatus: undefined,
        page: 1,
        size: 30,
      };
    },
    // 更新发票列表数据
    async refreshTableData(param) {
      param.taxNo = this.taxNo;
      if (this.oprtMode == 'SM') {
        this.$confirm('您当前处于扫码查询模式，切换至普通查询模式后会清空扫码查询模式的查询结果，您确认要继续吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(async () => {
            this.oprtMode = 'CX';
            await setTimeout(() => this.doGetDataList(param), 5);
          })
          .catch(() => {});
      } else {
        await setTimeout(() => this.doGetDataList(param), 5);
      }
    },
    // 已勾选汇总
    async doGetIncomeInvoicesYGXStatistics(param) {
      this.ygxLoading = true;
      const { success, data } = await summaryInvoiceFiling(param);
      if (success) {
        if (null != data && data.length > 0) {
          this.ygxSummary = data[0];
        }
      }
      this.ygxLoading = false;
    },
    async doGetDataList(param) {
      this.tableLoading = true;
      // 初始化表格数据
      this.tableData = [];
      // this.doGetIncomeInvoicesStatistics(param);
      this.doGetIncomeInvoicesYGXStatistics(param);

      const { success, data } = await searchInvoiceFilingPage(
        param
      );
      this.tableLoading = false;
      if (this.oprtMode == 'SM') {
        this.oprtMode = 'CX';
      }
      if (success) {
        this.tableData = data.records;
      }
    },
    // 点击导出
    async handleExport() {
      if (!this.selections || this.selections.length < 1) {
        this.toast('导出发票至少勾选一张', 'warning');
        return;
      }
      let ids = this.selections.map((i) => i.id);
      this.buttonLoading = true;
      const rsp = await downloadDeductExcel(ids);
      this.downloadFile(rsp);
      this.buttonLoading = false;
    },
    /* 发票详情 */
    async handleViewDetails(invoice) {
      this.drawer = !this.drawer;
      this.loading = true;
      const detailsData = await searchInvoiceFilingRedList({
        filingId: invoice.id
      });
      selectInvoice(invoice.invoiceId).then((res) => {
        if (res.success) {
          this.loading = false;
          let details = res.data.items;
          
          if (detailsData.success) {
            details = detailsData.data;
          }

          if (details) {
            details.forEach(
              (item) => (item.taxRate = Number(item.taxRate) * 100 + "%")
            );
            // 统计信息
            let total = {
              merchandiseName: "合计",
              amount: invoice.amountString
                ? invoice.amountString
                : invoice.amount,
              taxAmount: invoice.taxAmountString
                ? invoice.taxAmountString
                : invoice.taxAmount,
            };
            details.push(total);
            let sum = Number(invoice.sumAmount).toFixed(2);
            // let sumBig = this.handleSmallToCapitalize(sum);
            let priceTax = {
              merchandiseName: "价税合计（大写）",
              // specification: sumBig + "  （小写）￥" + sum,
            };
            details.push(priceTax);
          }
          let invoiceData = res.data;
          invoiceData.items = details;
          this.invoice = invoiceData;
        }
      });
    },
    /* 导出原件 */
    handleExportOriginal(invoice) {
      this.exportVisible = true;
      this.invoiceUpdate = {
        id: invoice.invoiceId,
        accountStatus: invoice.accountStatus,
        periodNo: invoice.periodNo,
        voucherNo: invoice.voucherNo,
        reimburseOrderNo: invoice.reimburseOrderNo,
        reimburseMatters: invoice.reimburseMatters
      };
    },
    handleCancelExportOriginal() {
      this.exportVisible = false; 
    },
    /* 完善档案 */
    handleImproveArchives(invoice) {
      this.updateVisible = true;
      this.invoiceUpdate = {
        id: invoice.invoiceId,
        accountStatus: invoice.accountStatus,
        periodNo: invoice.periodNo,
        voucherNo: invoice.voucherNo,
        reimburseOrderNo: invoice.reimburseOrderNo,
        reimburseMatters: invoice.reimburseMatters
      };
    },
    handleImproveArchivesSave() {
      this.invoiceUpdate = {};
      this.submitForm();
      this.toast('修改成功', 'success');
    },
    handleImproveArchivesCancel() {
      this.updateVisible = false;
    },
    /* 发票影像 */
    handleInvoiceImage(invoice) {
      invoice.loading = true;
      getInvoiceImageListById(invoice.invoiceId).then((res) => {
        if (res.success) {
          this.invoiceImageViewer = true;
          this.invoiceImages = res.data;
        }
        invoice.loading = false;
      });
    },
    /* 发票影像弹窗关闭 */
    handleImageViewerClose() {
      this.invoiceImageViewer = false;
      this.invoiceImages = [];
    },
    // 表格勾选
    handleSelection(val, row) {
      this.selections = val;
    }, 
    // 有效税额校验
    checkDeductTaxAmount(row) {
      if (row.deductTaxAmount > row.taxAmount) {
        this.toast('有效税额不可大于税额', 'warning');
        row.deductTaxAmount = row.taxAmount;
      }
    },
    // 格式化表格
    fmtTableColumn(row, column) {
      switch (column.property) {
        case 'classification':
          if (row[column.property] != null && row[column.property] != "") {
            if (row[column.property] === 1) {
              return '销项';
            } else if (row[column.property] === 2) {
              return '进项';
            }
          }
          return '--';
        case 'invoiceType':
          // return this.handleValueToLabel('DeductInvoiceType', row[column.property]);
          return this.handleValueToLabel('InvoiceType', row[column.property]);
        case 'checkStatus':
          return this.handleValueToLabel('DeductInvoiceCheckStatus', row[column.property]);
        case 'manageStatus':
          return this.handleValueToLabel('DeductInvoiceManageStatus', row[column.property]);
        case 'invoiceStatus':
          // return this.handleValueToLabel('DeductInvoiceStatus', row[column.property]);
          if (row[column.property] != null && row[column.property] != "") {
            if (row[column.property] === '0') {
              return '蓝票';
            } else if (row[column.property] === '1') {
              return '红票';
            } else if (row[column.property] === '2') {
              return '全额冲红';
            } else if (row[column.property] === '3') {
              return '作废';
            } else if (row[column.property] === '4') {
              return '部分冲红';
            }
          }
          return '--';
        case 'channel':
          if (row[column.property] != null && row[column.property] != "") {
            return this.getDictLabel(this.typeOfReceiptList, row[column.property]);
          }
          return "--";
        case 'invoiceTime':
          return this.$moment(row[column.property]).format('YYYY-MM-DD');
        case 'accountStatus':
          if (row[column.property] != null && row[column.property] != undefined) {
            if (row[column.property] === 0) {
              return '未记账';
            } else if (row[column.property] === 1) {
              return '已记账';
            }
          }
          return '--';
        case 'checkedTime':
          return row[column.property] == null ? '--' : this.$moment(row[column.property]).format('YYYY-MM-DD');
        case 'submitTime':
          return row[column.property] == null ? '--' : row[column.property];
        case 'surcharge':
          return row[column.property] == null ? '--' : Number(row[column.property]);
        case 'taxRate':
          if (row[column.property] && 'null' != row[column.property]) {
            return Number(row[column.property] * 100) + '%';
          } else {
            return '--';
          }
        default:
          return row[column.property];
      }
    },
    getGxfwEndDate() {
      const invoiceTimeEndStr = this.$store.state.deductAuthorize.invoiceTimeEnd;
      return new Date(invoiceTimeEndStr).getTime();
    },
    formatMoney(value) {
      return fmtMoney(value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/select.scss';

.ygx_total_box {
  display: flex;
  padding: 10px 16px;
  border-width: 1px;
  border-radius: 8px;
  border-color: #dddddd;
  border-style: solid;
  margin: 10px 10px 0px 10px;
  span {
    color: #333;
    font-size: 14px;
    margin-right: 48px;
    display: flex;
    align-items: center;
    label {
      font-weight: 800;
      font-size: 16px;
      color: red;
    }
  }
}
.content-wrap {
  position: relative;
}

.select-content {
  ::v-deep .select-form {
    width: auto;
    display: block;
  }

  ::v-deep .select-form > div {
    display: inline-flex;
  }
}

.content-main {
  display: flex;
  padding: 0 20px;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100% - 130px);

  .content-table {
    .table-statistics {
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ebf4ff;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;

      .statistics-left {
        display: flex;
        justify-content: space-between;

        div {
          margin-right: 20px;
        }

        div:first-child {
          margin-left: 10px;
        }
      }

      .statistics-right {
        display: flex;
        justify-content: space-between;

        div {
          margin-left: 10px;
        }

        div:last-child {
          margin-right: 10px;
        }
      }
    }
    
    ::v-deep .el-button {
      padding-right: 1px;
      padding-left: 1px;
    }
  }
}

.row-last:after {
  content: '';
  min-width: 300px;
}

.dialog-deduct-eport {
  .el-form {
    margin-top: 20px;
  }
}

.sync-data {
  top: 15px;
  right: 12px;
  position: absolute;
}
::v-deep .el-range-editor--mini .el-range-separator {
  line-height: inherit;
}
::v-deep .smcx-input .el-input__inner {
  height: 100%;
}
</style>
