import { get, post, download, put, del } from './index.js';

/**
 * 票据列表(分页)（全部）
 * @param {*} data
 */
export const getInvoiceCenterByPage = (data) => {
  return post('/invoice/center/page', data).then((res) => res);
};
/**
 * 票据列表(分页)（增值税票）
 * @param {*} data
 */
export const getVATInvoiceCenterByPage = (data) => {
  return post('/invoice/center/vatpage', data).then((res) => res);
};
/**
 * 票据列表(分页)（其他发票）
 * @param {*} data
 */
export const getOtherInvoiceCenterByPage = (data) => {
  return post('/invoice/center/otherpage', data).then((res) => res);
};
/**
 * 票据详情
 * @param {*} data
 */
export const selectInvoice = (param) => {
  return get('/invoice/details/' + param).then((res) => res);
};
/**
 * 发票更新
 * @param {*} data
 */
export const updateInvoiceById = (data) => {
  return put('/invoice', data).then((res) => res);
};
/**
 * 发票状态更新
 * @param {*} data
 */
export const updateInvoiceSettlementStatus = (param) => {
  return put('/invoice/settlement/status/' + param.invoiceIds + '/' + param.settlementStatus).then((res) => res);
};
/**
 * 批量生成PDF文件
 * @param {*} data
 */
export const batchGeneratePdf = (param) => {
  return get('/invoice/batchGeneratePdf/' + param.invoiceIds).then((res) => res);
};
/**
 * 批量生成PDF文件前置查询
 * @param {*} data
 */
export const batchGeneratePdfPreQuery = (param) => {
  return get('/invoice/batchGeneratePdfPreQuery/' + param.invoiceIds).then((res) => res);
};
/**
 *
 * 导入收票
 * @param {*} data
 */
export const invoiceImport = (data) => {
  return post('/image/invoice/import', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => res);
};
/**
 * 发票说明更新
 * @param {*} data
 */
export const invoiceExplainUpdate = (param) => {
  return put('/invoice/explain/' + param.ids, param.data).then((res) => res);
};
/**
 * 提交确认报销
 * @param {*} data
 */
export const updateInvoiceExplainReimburse = (param) => {
  return put('/invoice/explain/reimburse/' + param.ids, param.data).then((res) => res);
};
/**
 * 取消报销
 * @param {*} data
 */
export const cancelInvoiceReimburse = (param) => {
  return put('/invoice/explain/reimburse-cancel/' + param.ids, param.data).then((res) => res);
};
/**
 * 票据影像
 * @param {*} data
 */
export const getInvoiceImageListById = (data) => {
  return get('/invoice/image/list/' + data).then((res) => res);
};
/**
 * 重新推送
 * @param {*} data
 */
export const againPushInvoice = (param) => {
  return get('/invoice/again/push/' + param).then((res) => res);
};

/**
 * 获取手机扫描二维码
 * @param {*} data
 */
export const getPhoneScanningQRCode = () => {
  return download('/invoice/getPhoneScanningQRCode').then((res) => res);
};
/**
 * PDF文件上传
 * @param {*} data
 */
export const changePdfToImage = (data) => {
  return post('/image/invoice/pdf/change/image', data).then((res) => res);
};
/**
 * 获取识别批次号
 * @param {*} data
 */
export const getRecognizeBatchNo = () => {
  return get('/image/invoice/recognize/batch/no', {}).then((res) => res);
};
/**
 * 发票识别
 * @param {*} data
 */
export const recognize = (data) => {
  return post('/local/picture/recognize', data).then((res) => res);
};
/**
 * 发票识别
 * @param {*} data
 */
export const recognizeInvoicePicture = (data) => {
  return post('/image/invoice/picture/recognize', data, { timeout: 180000 }).then((res) => res);
};
/**
 * 发票识别 压缩文件夹
 * @param {*} data
 */
export const recognizeInvoicePictureZip = (data) => {
  return post('/image/invoice/picture/recognizezip', data, { timeout: 180000 }).then((res) => res);
};

// ofd文件识别
export const ofdRecognize = (data) => {
  return post('/image/invoice/ofdRecognize', data, { timeout: 180000 }).then((res) => res);
};

/**
 * 发票提交（新）
 * @param {*} data
 */
export const submitInvoice = (data) => {
  return post('/image/invoice/submit', data).then((res) => res);
};

/**
 * 发票批量删除
 * @param data List<Long> ids
 * @returns {Promise<unknown>}
 */
export const deleteInvoiceByIds = (param) => {
  return post('/invoice/batch/delete', param).then((res) => res);
};

/**
 * 发票批量推送
 * @param data List<Long> ids
 * @returns {Promise<unknown>}
 */
export const pushInvoiceByIds = (param) => {
  return post('/invoice/batch/push', param).then((res) => res);
};

export const uploadOFDFromLocal = (data) => {
  return post('/image/invoice/ofdUpload', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => res.data);
};

/**
 * 文件下载
 * @param data
 * @returns {Promise<any | never>}
 */
export const downOriginalFile = (data) => {
  return download('/invoice/downOriginalFile/', data).then((res) => res);
};

export const handleExportBatch = (data) => {
  return download('invoice/export/', data).then((res) => res);
};

/**
 * 扫码枪识别数据保存
 * @param param
 */
export const saveScanningGunRecognizeData = (param) => {
  return post('/scanning/gun', param).then((res) => res);
};

/**
 * 识别失败记录列表(分页)
 * @param {*} data
 */
export const getRecognizeFailureRecord = (data) => {
  return get('/image/invoice/recognize/failure/record', data).then((res) => res);
};

/**
 * 发票识别失败-编辑
 * @param {*} data
 */
export const updateImageInvoice = (param) => {
  return put('/image/invoice', param).then((res) => res);
};
/**
 * 图片编辑
 * @param {*} data
 */
export const editImageInvoice = (param) => {
  return get('/image/invoice/getImageInvoice/' + param).then((res) => res);
};
/**
 * 发票识别失败记录 - 影像
 * @param {*} data
 */
export const getInvoiceRecognizeImage = (param) => {
  return get('/image/invoice/image/' + param).then((res) => res);
};

/**
 * 发票识别失败-删除
 * @param {*} data
 */
export const deleteImageInvoice = (data) => {
  return del('/image/invoice/' + data).then((res) => res);
};

/**
 * 发票清单列表
 * @param {*} data
 */
export const getInvoiceListsById = (param) => {
  return get('/invoice/list/' + param).then((res) => res);
};

export const scannerInvoice = (param) => {
  return get(`/scanner/invoice/${param}`).then((res) => res);
};

/**
 * 发票清单影像
 * @param {*} data
 */
export const getInvoiceListImage = (param) => {
  return get('/invoice/list/image/' + param).then((res) => res);
};

/**
 * 发票清单编辑
 * @param {*} data
 */
export const updateInvoiceList = (param) => {
  return put('/invoice/list', param).then((res) => res);
};

/**
 * 发票清单删除
 * @param {*} param
 */
export const deleteInvoiceList = (param) => {
  return del('/invoice/list/' + param).then((res) => res);
};

/**
 * 批量稽核
 * @param {*} param
 */
export const buyerInfoAudit = (param) => {
  return put('/invoice/buyer/audit/' + param).then((res) => res);
};

/**
 * 重新匹配
 * @param {*} param
 */
export const againRecognize = (param) => {
  return post('/image/invoice/again/recognize', param).then((res) => res);
};
/**
 * 手工录入
 * @param {*} param
 */
export const invoiceManualInput = (param) => {
  return post('/invoice/manual/input', param).then((res) => res);
};
/**
 * 重复检查
 * @param {*} param
 */
export const checkInvoiceRepeat = (param) => {
  return get('/image/invoice/gun/collect', param).then((res) => res);
};

/**
 * 重复检查
 * @param {*} param
 */
export const unqueriedImage = (param) => {
  return get('/image/invoice/recognize/unqueried/image', param).then((res) => res);
};

//变更收票组织
export const ticketCollectorOrganization = (param) => {
  return post('/invoice/organization', param).then((res) => res);
};

// 发票预览
export const asyncInvoiceFile = (param) => {
  return get('/invoice/file', param).then((res) => res);
};

//批量下载 /invoice/download
export const invoiceDownload = (param) => {
  return download('/invoice/download', param).then((res) => res);
};
// /invoice/generate/pdf 手动生成PDF文件
export const generatePdf = (param) => {
  return post('/invoice/generate/pdf', param).then((res) => res);
};

// 查看发票附件
export const reqAttachmentByColInvoiceId = (colInvoiceId) => {
  return get(`/col/attachment/by-col-invoice-id/${colInvoiceId}`).then((res) => res);
};
// 查看上一次申请
export const getLastChoice = (data) => {
  return get(`/col/attachment/getDefault`,data).then((res) => res);
};