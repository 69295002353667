<template>
  <el-form
    class="income-wrap"
    ref="invoiceFilingFileRef"
    v-loading="uploadLoading"
  >
    <div class="edit-select">
      <div class="edit-select-item">
        <div class="downloadColumn">
          <span>PDF原文件：</span>
          <!-- <a
            :href="handlerDownloadInBatches()"
            >发票原文件下载（PDF）</a
          > -->
          <el-button type="text" @click="handlerDownloadInBatches('PDF')">发票下载（PDF）</el-button>

        </div>
      </div>
      <div class="edit-select-item">
        <div class="downloadColumn">
          <span>OFD原文件：</span>
          <!-- <a
            :href="handlerDownloadInBatches('OFD');"
            >发票原文件下载（OFD）</a
          > -->
          <el-button type="text" @click="handlerDownloadInBatches('OFD')">发票下载（OFD）</el-button>
        </div>
      </div>
      <div class="edit-select-item">
        <div class="downloadColumn">
          <span>XML原文件：</span>
          <!-- <a
            :href="handlerDownloadInBatches('XML')"
            >发票原文件下载（XML）</a
          > -->
          <el-button type="text" @click="handlerDownloadInBatches('XML')">发票下载（XML）</el-button>
        </div>
      </div>
      <div class="edit-select-item"></div>
    </div>
    <div class="edit-select">
      <div class="edit-select-foot">
        <el-button class="el-button-ext" @click="handleCancel">取消</el-button>
      </div>
    </div>
  </el-form>
</template>

<script>
import {  invoiceDownload } from "@/service/invoice-center";
export default {
  name: "invoiceFilingImport",
  components: { },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    invoiceUpdate: {
      type: Object
    }
  },
  data() {
    return {
      saving: false,
      dlFileParam: {}, 
      uploadLoading: false,
    };
  },
  computed: {},
  methods: {
    // 确定下载
    async handlerDownloadInBatches(fileTypes) {
      this.dlFileParam.invoiceIds = [this.invoiceUpdate.id];
      this.dlFileParam.fileTypes = [fileTypes];
      const rsp = await invoiceDownload(this.dlFileParam);
      this.downloadFile(rsp);
    },
    /* 重置表单 */
    resetForm() {
      this.$refs.invoiceFilingFileRef.resetFields();
      this.invoiceFilingFile.fileName = "";
      this.invoiceFilingFile.file = {};
    },
    //处理取消
    handleCancel() {
      this.$emit("handleCancelExportOriginal");
    },
  },
};
</script>

<style scoped lang="scss">
.income-wrap {
  padding: 0 24px;
}

::v-deep .el-form-item__label {
  padding: 0;
  line-height: 24px;
}

::v-deep .el-form-item__error {
  top: 58px;
  padding-top: 2px;
}

::v-deep .dialog-footer {
  padding-top: 25px;
  text-align: center;
}

  ::v-deep .el-select, ::v-deep .el-input {
    width: 453px;
  }

.edit-select {
  .edit-select-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .edit-select-item {
    margin-bottom: 50px;
  }

  .edit-select-item:last-child {
    margin-top: 60px;
  }

  .edit-select-foot {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;
  }
}

.edit-message {
  margin-top: 25px;
  margin-bottom: 32px;
  text-align: left;
  font-size: 14px;
  color: #f5222d;
}

.downloadColumn {
  font-size: 16px;
  margin: 0 auto;
  a {
    color: #1890ff;
    font-size: 16px;
    font-weight: 400;
    line-height: 2em;
    text-decoration: none;
    font-family: PingFangSC-Regular, PingFang SC;
  }
}

.downloadBtn {
  padding-left: 0px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #1890ff;
  font-weight: 400;
  line-height: 60px;
  outline: none;
}
</style>
