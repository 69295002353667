import { get, post, download, put, del } from './index.js';

/**
 * 票据列表(分页)（全部）
 * @param {*} data
 */
export const searchInvoiceFilingPage = (data) => {
  return post('/invoice-filing/searchInvoiceFilingPage', data).then((res) => res);
};

/**
 * 查询详情
 * @param {*} data
 */
export const getInvoiceFiling = (param) => {
  return get('/invoice-filing/getInvoiceFiling/' + param).then((res) => res);
};

/**
 * 发票归档统计
 * @param data
 * @returns {Promise<unknown>}
 */
 export const summaryInvoiceFiling = data => {
  return post("/invoice-filing/summary", data).then(res => res);
}

/**
 * 保存
 * @param {*} data
 */
export const saveInvoiceFiling = (data) => {
  return put('/invoice-filing/saveInvoiceFiling', data).then((res) => res);
};

/**
 * 导出
 * @param param
 */
export const exportInvoiceFiling = (data) => {
  return download('/invoice-filing/exportInvoiceFiling', data).then((res) => res);
}; 



/**
 * 发票归档-冲红类列表
 * @param {*} data
 */
 export const searchInvoiceFilingRedList = (data) => {
  return post('/invoice-filing/searchInvoiceFilingRedList', data).then((res) => res);
};